import { Component, ContentChildren, ElementRef, Input, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { ModalService } from '../../../services/modal.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'modal',
  standalone: true,
  imports: [],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent {

    @Input() id!: string;
    @Input() backdropClose: boolean = true;
    @Input() showBackdrop: boolean = true;
    @Input() wrapperClass: string = '';
    public data = new Subject<any>();
    public element: HTMLElement;
    @ViewChildren('modalElement') modalElement!: QueryList<ElementRef>

    constructor(private modalService: ModalService, private el: ElementRef){
        this.element = this.el.nativeElement as HTMLElement
    }

    ngOnInit() {
        let modal = this;

        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        document.body.appendChild(this.element);

        this.element.addEventListener('click', function (e: any) {
            if (e.target.className === 'actions-backdrop' && modal.backdropClose) {
               modal.modalService.close(modal.id)
            }
        });

        this.modalService.add(this);
    }

    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

}
